<template>
  <div class="row" id="my-filter">
    <modal name="open_to_settings_table" :adaptive="true" height="auto" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable class="list-group py-4" tag="ul" v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove"
            @start="isDragging=true" @end="isDragging=false">
            <transition-group type="transition" :name="'flip-list'">
              <div class="col-12 list-group-item w-300px d-flex justify-content-between "
                v-for="element in fieldsToViewEdit" :key="element.sort">
                <span class="col-6"> {{$t(element.name)}}</span>
                <span class="col-6 d-flex justify-content-center">
                  <input type="checkbox" style="font-size: 10px " v-model="element.type_status"></span>
              </div>
            </transition-group>
          </draggable>
          <button @click="closeModal"
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5">{{
            $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button @click="onSubmitToSaveSettingTable"
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer">{{ $t('esc.new_send') }}
          </button>
        </div>
      </div>
    </modal>
    <div class="col-md-12 px-7">
      <b-card class="row" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row py-0 my-0 mx-0 items-end" v-if="filters">
              <div
                class="col-xl-5 col-lg-5 col-md-4 col-sm-12 my-2 row px-0 mx-0 d-flex justify-content-start items-center py-1">
                <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
                  <i class="fa fa-chevron-left"></i>
                </span>
                <input-filter :helper-text="$t('general.search')" title="general.search"
                  :placeholder="$t('general.search_filter')" :model.sync="filters.search"></input-filter>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 my-lg-2  my-sm-0 row mx-0 px-0 py-1">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" title="general.start_date"
                    :model.sync="filters.start_date" name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" title="general.end_date"
                    :model.sync="filters.end_date" name="end_date">
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-2 my-sm-0 row mx-0">
                <div class="col-md-12 my-md-0">
                  <select-filter :helper-text="$t('sample.status_by_filter')" title="general.status_filter"
                    :model.sync="filters.sample_status_id" :values="sampleStatuses"></select-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-lg-0 m-md-0 my-sm-5 pr-0 row">
                <div class="col-12 pr-0">
                  <button @click.prevent="filterResult(null)"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button type="button" @click="exportData()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.export_to_list') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </b-card>
    </div>
    <context-menu :display="showContextMenu" ref="menu">
          <ul>
            <li @click="openNewTab"> {{ $t('order.open_new_tab') }} </li>
          </ul>
    </context-menu>
    <div class="col-md-12">
      <dashboard-box :title="$t('messages.samples')">
        <template v-slot:title>
          <span>{{ $t('messages.samples') }}</span>
        </template>
        <template v-slot:preview>
          <sample-index-nav-panel v-if="filters" :activeNav="filters.sample_type_id"
            @changeActiveNav="onChangeActiveNav"></sample-index-nav-panel>
          <div class="fixed" style="position: fixed !important; top: 230px; right: 15px;">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span v-html="getIconByKey('icons.waybill.edit', {
                class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
              })">
              </span>
            </div>
          </div>
          
          <div class="row">
            
            <div class="col-12">
              <span class="h4 float-right mr-4">
                <b>{{ $t('order.summary_count')}} </b>: {{ amount_total }} /
                <b>{{ $t('order.loaded_amount')}} </b>: {{ amount_delivered }} /
                <b>{{ $t('order.summary_amount')}} </b>: {{ amount }}
              </span>
            </div>
          </div>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
                @rightClick="onRightClick"
                tableVariant="''" 
                :hover="false" 
                :outlined="false" 
                :striped="false" 
                :no-border-collapse="true"
                :borderless="true" 
                :bordered="false" 
                :items="formattedItems" 
                :fields.sync="tableFields" 
                :sortBy.sync="order" 
                :sortDesc.sync="sort"
                :page.sync="pageNumberProxy"
                :perPage.sync="perPageProxy" 
                @rowClicked="onRowClicked" 
                @hitBottom="onHitBottom"
                @onChangeSearchFilter="onChangeSearchFilter" 
                :infiniteId="infiniteId"
          ></data-table>
          
          </div>
          

            <div class="row">
            <div class="col-12">
              <span class="h4 float-right mr-4">
                <b>{{ $t('order.summary_count')}} </b>: {{ amount_total }} /
                <b>{{ $t('order.loaded_amount')}} </b>: {{ amount_delivered }} /
                <b>{{ $t('order.summary_amount')}} </b>: {{ amount }}
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  EXPORT,
  LOADING,
  ERROR,
  ITEMS,
  PAGE_NUMBER,
  PER_PAGE,
  SET_PER_PAGE,
  SET_PAGE_NUMBER,
  SAMPLE_ITEMS,
  HANDLE_INFINITE_SCROLL,
  SAMPLES,
  FILTER,
  SET_FILTER,
  SET_LOADING,
  RESET_ITEMS
} from "@/core/services/store/sample/samples.index.module";

// COMPONENTS
import DataTable from "@/assets/components/dataTable/DataTable";
import DashboardBox from "@/assets/components/DashboardBox";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import SampleIndexNavPanel from "@/view/pages/samples/layout/SampleIndexNavPanel"

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import { CREATE_ITEM, GET_ITEMS, UPDATE_ITEM_BY_ID } from "@/core/services/store/REST.module";
import {SAMPLE_INDEX_START_DATE_STORAGE_NAME, SAMPLE_INDEX_END_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

import * as _ from 'lodash';
import draggable from 'vuedraggable'
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT  } from "@/core/config/constant";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";

export default {
  name: "Samples.vue",
  components: {
    DataTable,
    DashboardBox,
    DateFilter,
    InputFilter,
    SelectFilter,
    SampleIndexNavPanel,
    draggable,
    ContextMenu
  },
  data() {
    return {
      showContextMenu: false,
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      sort: "desc",
      order: "id",
      amount_total:0,
      amount_delivered:0,
      amount:0,
      newTabRoute:null,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: [],
        }
      ],
      defaultFilters: {
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        status: null,
        search: null,
        sample_status_id: 1,
        sample_type_id: 1,
      },
      sampleSummaryUrl: 'api/samples/summary'
    }
  },
  computed: {
    ...mapGetters({
      'loading': LOADING,
      'error': ERROR,
      'samples': ITEMS,
      'pageNumber': PAGE_NUMBER,
      'perPage': PER_PAGE,
      'items': SAMPLE_ITEMS,
      'originalSamples': SAMPLES,
      'filter': FILTER
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    perPageProxy: {
      set(value) {
        this.setPageNumber(1);
        this.setPerPage(value);
      },
      get() {
        return this.perPage;
      },
    },
    pageNumberProxy: {
      set(value) {
        this.setPageNumber(value);
      },
      get() {
        return this.pageNumber;
      },
    },
    formattedItems(){
      let items = [];
      this.amount_delivered=0
      this.amount=0
      this.amount_total=0
      this.amount_total=this.samples.data?Object.keys(this.samples.data).length:0
      for(let key in this.samples.data){
        let currentData = this.samples.data[key];
        items.push({
          'id': currentData.id,
          'sample_type_id': currentData.sample_type_id,
          'image': currentData.image,
          'product_name': currentData.name,
          'order_date': moment(currentData.order_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'updated_at': moment(currentData.updated_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'completed_at': currentData.completed_at ? moment(currentData.completed_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '-',
          'collection_number': currentData.collection_number,
          'model_number': currentData.model_number,
          'customer_name': currentData.company.name,
          'deadline': currentData.deadline != null ? moment(currentData.deadline, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '-',
          'model': currentData.model_name || '-',
          'model_description': currentData.product_model ? currentData.product_model.translations[0].name : '-',
          'amount': +currentData.sample_type_id === 1 ? currentData.amount + ' / ' + currentData.delivered_amount : '-',
          'copied_count': currentData.copied_count,
          'is_completed': currentData.is_completed,
          'status': currentData.sample_status.name, // TODO: need to change after translation added
          'status_id': currentData.sample_status.id,
        });
        this.amount_delivered+=currentData.delivered_amount
        this.amount+=currentData.amount
    }
    return items;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (let [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          let options = [];
          if (key === 'status') {
            for (const [key, value] of Object.entries(this.sampleStatuses)) {
              options.push({
                value: key,
                text: value,
              })
            }
          }
          fields.push({
            'key': key,
            'label': this.$t(value.name),
            'sortable': value.status,
            'value': this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : '',
            'sort': value.sort,
            'class': "text-center border border-top-0 border-left-0",
            'tdClass': "cursor-pointer",
            'searchable': value.searchable,
            'searchable_type': value.searchable_type,
            'searchable_options': options,
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    }
  },
  mounted() {

    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('general.sample') },
    ]);
    let self = this;

    if (!this.isUserGranted('Sample', ['viewAny'])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }
    
    this.filters.end_date = localStorage.hasOwnProperty(SAMPLE_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(SAMPLE_INDEX_END_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(SAMPLE_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(SAMPLE_INDEX_START_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);
      
    this.$store.dispatch(GET_ITEMS, {
      url: 'api/statuses',
      filters: {
        type_id: 1
      },
      acceptPromise: true,
      showLoading: false,
    }).then(result => {
      if (result.status) {
        self.sampleStatuses = self.convertArrayToObjectByKey(result.data, 'id', 'translations.0.name')
      }
    });
    this.getTableItem();

    


   

    setTimeout(this.onHitBottom, 1000);
  },
  methods: {
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    ...mapMutations({
      'setPerPage': SET_PER_PAGE,
      'setPageNumber': SET_PAGE_NUMBER,
      'setLoading': SET_LOADING,
      'setFilter': SET_FILTER,
    }),
    
    exportData() {
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'sample_status_id', this.filters.sample_status_id);
      this.$set(filters, 'sample_type_id', this.filters.sample_type_id);
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);

      this.$store.dispatch(EXPORT, {
        filters: filters
      }).then(result => {
        if (result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'order_list' + day + '.pdf';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
    },
    getTableItem() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'sample'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name': this.$t(value.name),
              'sort': value.sort,
              'type': value.type,
              'type_status': value.status,
              'key': key,
              'searchable': !!value.searchable,
              'searchable_type': value.searchable_type,
              'searchable_options': value.searchable_options,
            })
            self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
        }
      });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};
      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
          searchable: !!this.fieldsToViewEdit[i].searchable,
          searchable_type: this.fieldsToViewEdit[i].searchable_type,
        };
      }

      let contents = {
        model_name: 'sample',
        table_fields: json,
      }
      let payload = {
        url: 'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    resetFilters() {
      this.amount_total=0;
      this.amount_delivered=0;
      this.amount=0;
      this.sort="desc";
      this.order= "id";
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        status: null,
        search: null,
        order: "",
        sort: "",
        sample_status_id: 1,
        sample_type_id: 1
      })
    },
    onHitBottom($state = null) {
      if (!this.isUserGranted('Sample', ['viewAny'])) {
        return false;
      }
      if (this.loading) {
        return;
      }
      console.log($state)
      
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);

      this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
        '$state': $state,
        filters: filters
      }).then(result => {
        if (result.status) {
          this.infiniteId++;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
      })

    },
    filterResult() {
      this.infiniteId++;
      this.onHitBottom(null);
      localStorage.setItem(SAMPLE_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(SAMPLE_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.end_date));
    },
    onChangeActiveNav(value) {
      this.infiniteId++;
      this.$set(this.filters, 'sample_type_id', value);
      this.filters.sample_type_id = value;
      this.$store.dispatch(RESET_ITEMS);
    },
    onRowClicked(item, index, event) {
      if (this.$route.query.redirect && this.$route.query.redirectName === 'order') {
        let self = this;
        this.sweetAlertConfirm(this.$t('order.are_you_sure_you_want_to_use_this_sample_for_order'), null, null, {
          'yes': this.$t('general.yes'),
          'no': this.$t('general.no'),
        }).then(result => {
          if (result) {
            self.$router.replace({ name: 'order.create', params: self.$route.query, query: { ...this.$route.query, sample_id: this.items[index].id } })
          }
        })
      } else {
        if (this.isUserGranted('Sample', ['view', 'update'])) {
          this.$router.push({
            name: 'sample.product.edit', params: {
              id: this.items[index].id,
              contentType: this.items[index].sample_type_id, sample: this.originalSamples[index]
            }
          })
        }
      }
    },
    openNewTab(){
      if (this.isUserGranted('Sample', ['view', 'update'])) {
        window.open(this.newTabRoute.href,'_blank');
      }
    },
    onRightClick(item, index, e){
      this.$refs.menu.open(e);
      this.newTabRoute=this.$router.resolve({name: 'sample.product.edit', params: {id: this.items[index].id,contentType: this.items[index].sample_type_id, sample: this.originalSamples[index]}});
    },
    onContextMenu(event) {
      event.preventDefault();
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  }
}
</script>

<style scoped>
*:focus {
  outline: none;
}
.card-body {
  padding: 1rem;
}
.sample-filter {
  width: 100%;
}

</style>
